import React, { useState }  from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import { deleteData } from '../../../../services/services';

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0px!important"
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  infosFrom: {
    width: 80
  },
  infosDate: {
    width: 215
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const Profile = (props) => {
  const { className, users, setUsers, token, ...rest } = props;
  const classes = useStyles();
  const [displayDeleteProfile, setDisplayDeleteProfile] = useState(false);
  const [deletedProfile, setDeletedProfile] = useState(false);

  const handleDisplayDeleteProfile = () => {
    if(!displayDeleteProfile) {
      setDisplayDeleteProfile(true);
    } else {
      setDisplayDeleteProfile(false);
    }
  };

  const deleteProfile = async () => {
    const profileDeletion = await deleteData('data/user/'+token);
    if(profileDeletion && profileDeletion.ok) {
      setDeletedProfile(true);
      setUsers([]);
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Profile"
      />
      <Divider />
      <CardContent className={classes.root}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Valeur</TableCell>
                </TableRow>
              </TableHead>
              {users && Array.isArray(users) && users.map(user => (
              <TableBody key={(user.user.id || user.user._id)}>
                {user.user && user.user.firstname && 
                  <TableRow>
                    <TableCell>
                      Firstname
                    </TableCell>
                    <TableCell>
                      {user.user.firstname}
                    </TableCell>
                  </TableRow>
                }
                {user.user && user.user.lastname && 
                  <TableRow>
                    <TableCell>
                      Lastname
                    </TableCell>
                    <TableCell>
                      {user.user.lastname}
                    </TableCell>
                  </TableRow>
                }
                {user.user && user.user.pictureUrl && 
                  <TableRow>
                    <TableCell>
                      Picture
                    </TableCell>
                    <TableCell>
                      {user.user.pictureUrl}
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
              ))}
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      {!displayDeleteProfile && !deletedProfile &&
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleDisplayDeleteProfile}
        >
          Delete profile informations
        </Button>
      </CardActions>
      }
      {displayDeleteProfile && !deletedProfile &&
        <CardActions>
          <Button
            className={classes.uploadButton}
            color="primary"
            variant="text"
            onClick={deleteProfile}
          >
            Confirm !
          </Button>
          <Button 
            variant="text"
            onClick={handleDisplayDeleteProfile}
          >
            Cancel
          </Button>
        </CardActions>
      }
      {deletedProfile &&
      <CardActions>
        <Typography variant="body1">Your profile informations are now deleted <span role="img" aria-label="burn profile">🧾 🔥</span></Typography>
      </CardActions>
      }
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;

const RGPD_API_URL = (window && window._env_) ? window._env_.API_URL : process.env.API_URL;

export const fetchData = async (path) => {
  const promise = new Promise((resolve => {
    fetch(`${RGPD_API_URL}${path}`, {
      crossDomain: true,
      method: 'GET',
      headers: {
        'Content-Type':'application/json'
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        resolve(responseJson);
      })
  }));
  return promise;
};

export const deleteData = async (path) => {
  const promise = new Promise((resolve => {
    fetch(`${RGPD_API_URL}${path}`, {
      crossDomain: true,
      method: 'DELETE',
      headers: {
        'Content-Type':'application/json'
      }
    })
      .then(response => response.json())
      .then(responseJson => {
        resolve(responseJson);
      })
  }));
  return promise;
};

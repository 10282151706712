import React, { useState }  from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { StatusBullet } from '../../../../components';

import { deleteData } from '../../../../services/services';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: "0px!important"
  },
  inner: {
    minWidth: 800
  },
  infosFrom: {
    width: 80
  },
  infosDate: {
    width: 215
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const msgTypeColors = {
  user: 'success',
  bot: 'info'
};

const Messages = props => {
  const { className, messages, setMessages, token, ...rest } = props;
  const classes = useStyles();
  const [displayDeleteMessage, setDisplayDeleteMessage] = useState(false);
  const [deletedMessage, setDeletedMessage] = useState(false);

  const handleDisplayDeleteMessage = () => {
    if(!displayDeleteMessage) {
      setDisplayDeleteMessage(true);
    } else {
      setDisplayDeleteMessage(false);
    }
  };

  const deleteMessage = async () => {
    const messageDeletion = await deleteData('data/messages/'+token);
    if(messageDeletion && messageDeletion.ok) {
      setDeletedMessage(true);
      setMessages([]);
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Messages"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.infosFrom}>From</TableCell>
                  <TableCell>Content</TableCell>
                  <TableCell className={classes.infosDate}>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {messages && messages.map(message => (
                  <TableRow
                    hover
                    key={message._id}
                  >
                    <TableCell>
                      {message.is_bot === true && 
                        <div className={classes.statusContainer}>
                          <StatusBullet
                            className={classes.status}
                            color={msgTypeColors.bot}
                            size="sm"
                          />
                          Bot
                        </div>
                      }
                      {message.is_bot === false && 
                        <div className={classes.statusContainer}>
                          <StatusBullet
                            className={classes.status}
                            color={msgTypeColors.user}
                            size="sm"
                          />
                          User
                        </div>
                      } 
                    </TableCell>
                    <TableCell>
                      {message.is_bot === false && 
                        <div>
                          {message.text_in}
                        </div>
                      }
                      {message.is_bot === true && 
                        <div>
                          {message.text_out.type === "text" && 
                            <div>
                            {message.text_out.objectAttachment && message.text_out.objectAttachment.text && 
                              <div>
                                {message.text_out.objectAttachment.text}
                              </div>
                            }
                            </div>
                          }
                          {message.text_out.type === "button" && 
                          <div>
                            {message.text_out.objectAttachment && message.text_out.objectAttachment.text && 
                              <div>
                                {message.text_out.objectAttachment.text}
                              </div>
                            }
                            {message.text_out.objectAttachment && message.text_out.objectAttachment.buttons && Array.isArray(message.text_out.objectAttachment.buttons) &&  message.text_out.objectAttachment.buttons.map(button => (
                              <div key={(Math.random()*10000)}>
                                <b>Button :</b> {button.title}
                              </div>
                            ))}
                          </div>
                          }
                          {message.text_out.type === "quick_reply" && 
                          <div>
                            {message.text_out.objectAttachment && message.text_out.objectAttachment.text && 
                              <div>
                                {message.text_out.objectAttachment.text}
                              </div>
                            }
                            {message.text_out.objectAttachment && message.text_out.objectAttachment.buttons && Array.isArray(message.text_out.objectAttachment.buttons) &&  message.text_out.objectAttachment.buttons.map(button => (
                              <div key={(Math.random()*10000)}>
                                <b>Quick reply :</b> {button.title}
                              </div>
                            ))}
                          </div>
                          }
                          {message.text_out.type === "carousel" && 
                          <div>
                            {message.text_out.objectAttachment && message.text_out.objectAttachment.elements && Array.isArray(message.text_out.objectAttachment.elements) &&  message.text_out.objectAttachment.elements.map(element => (
                              <div key={(Math.random()*10000)}>
                                <b>Carrousel :</b> {element.title}
                              </div>
                            ))}
                          </div>
                          }
                        </div>
                      }
                    </TableCell>
                    <TableCell>
                    {message.time_received_mongo}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      {!displayDeleteMessage && !deletedMessage &&
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleDisplayDeleteMessage}
        >
          Delete messages
        </Button>
      </CardActions>
      }
      {displayDeleteMessage && !deletedMessage &&
        <CardActions>
          <Button
            className={classes.uploadButton}
            color="primary"
            variant="text"
            onClick={deleteMessage}
          >
            Confirm !
          </Button>
          <Button 
            variant="text"
            onClick={handleDisplayDeleteMessage}
          >
            Cancel
          </Button>
        </CardActions>
      }
      {deletedMessage &&
      <CardActions>
        <Typography variant="body1">Your messages are now deleted <span role="img" aria-label="trash chat bubble">💬 🗑</span></Typography>
      </CardActions>
      }
    </Card>
  );
};

Messages.propTypes = {
  className: PropTypes.string
};

export default Messages;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { fetchData } from '../../services/services';

import {
  Messages,
  Profile
} from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Info = (props) => {
  const classes = useStyles();
  const token = props.match.params.token;

  const [messages, setMessages] = useState();
  const [users, setUsers] = useState();

  const fetchMessages = async (token) => {
    const msgs = await fetchData('data/messages/'+token);
    if(msgs) setMessages(msgs);
    return msgs;
  };

  const fetchProfile = async (token) => {
    const users = await fetchData('data/user/'+token);
    if(users) setUsers(users);
    return users;
  };

  useEffect(() => {
    fetchMessages(token);
    fetchProfile(token);
  }, [token]);

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <Profile users={users} setUsers={setUsers} token={token} />
        </Grid>
        <Grid
          item
          lg={12}
          md={12}
          xl={12}
          xs={12}
        >
          <Messages messages={messages} setMessages={setMessages} token={token} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Info;
